import React from "react";
import PrivacyPolicy from "../../sub-components/core";
import styles from "./style.module.scss";

const View = () => {
  return (
    <PrivacyPolicy
      classes={{ content: styles.content }}
    />
  );
};

export default View;
