import React from "react";
import classNames from "classnames";
import Header from "components/header";
import Footer from "components/footer";
import styles from './style.module.scss';

interface PrivacyPolicyProps {
  classes?: {
    container?: string;
    content?: string;
  };
}
const PrivacyPolicy = ({ classes }: PrivacyPolicyProps) => {
  return (
    <div className={classNames(styles.container, classes?.container)}>
      <Header />
      <div className={classNames(styles.content, 'permalinkSection', classes?.content)}>
        <div className={styles.title}>Privacy Policy</div>
        <div className={styles.paragraph}>This Privacy Policy describes how sitedb.io (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.</div>
        <div className={styles.header2}>Contact</div>
        <div className={styles.paragraph}>After reviewing this policy, if you have additional questions, want more information about our privacy practices, or would like to make a complaint, please contact us by e-mail at contact@sitedb.io</div>
        <div className={styles.header2}>Collecting Personal Information</div>
        <div className={styles.paragraph}>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information about an identifiable individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</div>
        <ul className={styles.list}>
          <li className={classNames(styles.textContent, styles.underlined)}>Device information</li>
            <ul className={styles.list}>
              <li className={styles.textContent}><span className={styles.bold}>Purpose of collection:</span> to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.</li>
              <li className={styles.textContent}><span className={styles.bold}>Source of collection:</span> Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.</li>
              <li className={styles.textContent}><span className={styles.bold}>Disclosure for a business purpose:</span> shared with our service processors.</li>
              <li className={styles.textContent}><span className={styles.bold}>Personal Information collected:</span> version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.</li>
            </ul>
          <li className={classNames(styles.textContent, styles.underlined)}>Order information</li>
            <ul className={styles.list}>
              <li className={styles.textContent}><span className={styles.bold}>Purpose of collection:</span> to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
              <li className={styles.textContent}><span className={styles.bold}>Source of collection:</span> collected from you.</li>
              <li className={styles.textContent}><span className={styles.bold}>Disclosure for a business purpose:</span> shared with our sales channels, payment gateways, shipping and fulfillment services.</li>
              <li className={styles.textContent}><span className={styles.bold}>Personal Information collected:</span> name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number.</li>
            </ul>
          <li className={classNames(styles.textContent, styles.underlined)}>Customer support information</li>
            <ul className={styles.list}>
              <li className={styles.textContent}><span className={styles.bold}>Purpose of collection:</span> to provide customer support.</li>
              <li className={styles.textContent}><span className={styles.bold}>Source of collection:</span> collected from you</li>
              <li className={styles.textContent}><span className={styles.bold}>Disclosure for a business purpose:</span> shared with customer support agents</li>
              <li className={styles.textContent}><span className={styles.bold}>Personal Information collected:</span> sitedb.io account related information</li>
            </ul>
        </ul>
        <div className={styles.header2}>Sharing Personal Information</div>
        <div className={styles.paragraph}>We share your Personal Information with service providers to help us provide our services and fulfill our contracts with you, as described above. For example:</div>
        <ul className={styles.list}>
          <li className={styles.textContent}>We use third party services to provide payment processing, authentication, fulfill our contract, arrange for shipping and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</li>
          <li className={styles.textContent}>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</li>
        </ul>
        <div className={styles.header2}>Behavioral Advertising</div>
        <div className={styles.paragraph}>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For example:</div>
        <ul className={styles.list}>
          <li className={styles.textContent}>We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal Information here: <a className={styles.link} href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a className={styles.link} href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</li>
        </ul>
        <div className={styles.paragraph}>For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at <a className={styles.link} href="https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>.</div>
        <div className={styles.paragraph}>You can opt out of targeted advertising by:</div>
        <ul className={styles.list}>
          <li className={styles.textContent}>FACEBOOK - <a className={styles.link} href="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</a></li>
          <li className={styles.textContent}>GOOGLE - <a className={styles.link} href="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</a></li>
        </ul>
        <div className={styles.paragraph}>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: <a className={styles.link} href="https://optout.aboutads.info/">https://optout.aboutads.info/</a>.</div>
        <div className={styles.header2}>Using Personal Information</div>
        <div className={styles.paragraph}>We use your personal Information to provide our services to you, which includes: offering products for sale, processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.</div>
        <div className={styles.header3}>Lawful basis</div>
        <div className={styles.paragraph}>Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process your personal information under the following lawful bases:</div>
        <ul className={styles.list}>
          <li className={styles.textContent}>Your consent;</li>
          <li className={styles.textContent}>The performance of the contract between you and the Site;</li>
          <li className={styles.textContent}>Compliance with our legal obligations;</li>
          <li className={styles.textContent}>To protect your vital interests;</li>
          <li className={styles.textContent}>To perform a task carried out in the public interest;</li>
          <li className={styles.textContent}>For our legitimate interests, which do not override your fundamental rights and freedoms.</li>
        </ul>
        <div className={styles.header3}>Retention</div>
        <div className={styles.paragraph}>When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.</div>
        <div className={styles.header3}>Automatic decision-making</div>
        <div className={styles.paragraph}>If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.</div>
        <div className={styles.paragraph}>We may engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.</div>
        <div className={styles.paragraph}>Our processors use limited automated decision-making to prevent fraud that does not have a legal or otherwise significant effect on you.</div>
        <div className={styles.paragraph}>Services that include elements of automated decision-making include:</div>
        <ul className={styles.list}>
          <li className={styles.textContent}>Temporary blacklist of IP addresses associated with repeated failed transactions. This blacklist persists for a small number of hours.</li>
          <li className={styles.textContent}>Temporary blacklist of credit cards associated with blacklisted IP addresses. This blacklist persists for a small number of days.</li>
        </ul>
        <div className={styles.header2}>Your rights</div>
        <div className={styles.header3}>GDPR</div>
        <div className={styles.paragraph}>If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information above.</div>
        <div className={styles.header3}>CCPA</div>
        <div className={styles.paragraph}>If you are a resident of California, you have the right to access the Personal Information we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information above.</div>
        <div className={styles.paragraph}>If you would like to designate an authorized agent to submit these requests on your behalf, please contact us at the address above.</div>
        <div className={styles.header2}>Cookies</div>
        <div className={styles.paragraph}>A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.</div>
        <div className={styles.paragraph}>The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.</div>
        <div className={styles.paragraph}>You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.</div>
        <div className={styles.paragraph}>Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block, manage or filter cookies can be found in your browser’s help file or through such sites as: <a className={styles.link} href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.</div>
        <div className={styles.paragraph}>Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our advertising partners. To exercise your rights or opt-out of certain uses of your information by these parties, please follow the instructions in the “Behavioural Advertising” section above.</div>
        <div className={styles.header3}>Do Not Track</div>
        <div className={styles.paragraph}>Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter our data collection and usage practices when we detect such a signal from your browser.</div>
        <div className={styles.header2}>Changes</div>
        <div className={styles.paragraph}>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</div>
        <div className={styles.header2}>Complaints</div>
        <div className={styles.paragraph}>As noted above, if you would like to make a complaint, please contact us by e-mail using the details provided under “Contact” above.</div>
        <div className={styles.paragraph}>If you are not satisfied with our response to your complaint, you have the right to lodge your complaint with the relevant data protection authority.</div>
        <div className={styles.paragraph}>Last updated: January 05, 2023</div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
