import React, { useState, useEffect } from "react";
import Header from "components/header";
import Footer from "components/footer";
import { getSafeServiceErrorMessage } from "utils/service";
import { useRegisterGuestMutation } from "services/pre-launch-backend";
import InfoModal from "./sub-components/info-modal";
import InvitationForm from "./sub-components/invitation-form";
import background from "./assets/background.jpg";
import styles from "./style.module.scss";

const ComingSoon = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [registerGuest, { error, isSuccess, isLoading }] = useRegisterGuestMutation();
  const errorMessage = getSafeServiceErrorMessage(error);

  useEffect(() => {
    isSuccess && setModalVisibility(true);
  }, [isSuccess]);

  return (
    <div className={styles.comingSoonContainer}>
      <Header />
      <div className={styles.highlight} style={{ backgroundImage: `url(${background})` }}>
        Live monitoring for all of your web services
      </div>
      <div className={styles.notify}>
        <div className={styles.callText}>Get notified when we launch</div>
        <InvitationForm
          error={errorMessage}
          isLoading={isLoading}
          onSubmit={({ email }) => registerGuest({ email })}
        />
      </div>
      <Footer />
      <InfoModal
        open={modalVisibility}
        onClose={() => setModalVisibility(false)}
      />
    </div>
  );
};

export default ComingSoon;
