import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./style.module.scss";

type FormValues = {
  email: string;
};
interface InvitationFormProps {
  error: string;
  isLoading: boolean;
  onSubmit: SubmitHandler<FormValues>;
}
const InvitationForm = ({ error, isLoading, onSubmit }: InvitationFormProps) => {
  const { handleSubmit, register, formState } = useForm<FormValues>();
  const { errors } = formState;

  return (
    <form className={styles.invitationFormContainer} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.primaryRow}>
        <input
          className={styles.emailField}
          placeholder="Email address"
          {...register("email", { // spreads: onChange, onBlur, name and ref.
            required: "email is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          disableRipple
          loading={isLoading}
          classes={{ root: styles.submitButton }}
        >
          Notify Me
        </LoadingButton>
      </div>
      <div className={styles.validationError}>{errors.email && errors.email.message}</div>
      {error && <div className={styles.invitationError}>{error}</div>}
    </form>
  );
};
InvitationForm.defaultProps = {
  onSubmit: () => {},
};

export default InvitationForm;
