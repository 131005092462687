import React from "react";
import { ReactComponent as SvgLogo } from "assets/svg/logo.svg";
import styles from "./style.module.scss";

const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <SvgLogo />
    </div>
  );
};

export default Header;
