import React from "react";
import classNames from "classnames";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";
import Icon from "@mui/material/Icon";
import { ReactComponent as FistPunchLeft } from "assets/svg/fist-punch-left.svg";
import { ReactComponent as FistPunchRight } from "assets/svg/fist-punch-right.svg";
import styles from "./style.module.scss";

interface InfoModalProps {
  onClose: (param0?: any) => any;
  open: boolean;
}
const InfoModal = ({ onClose, open }: InfoModalProps) => {
  return (
    <Dialog classes={{ paper: styles.dialog }} onClose={onClose} open={open} aria-labelledby="info-dialog">
      <div className={classNames('material-icons', styles.header)}>
        <Icon className={styles.icon} onClick={onClose}>clear</Icon>
      </div>
      <div className={styles.body}>
        <div className={styles.symbol}>
          <SvgIcon fontSize="inherit">
            <FistPunchLeft />
          </SvgIcon>
          <SvgIcon fontSize="inherit">
            <FistPunchRight />
          </SvgIcon>
        </div>
        <div className={styles.message}>You're on the list!</div>
      </div>
    </Dialog>
  );
};

export default InfoModal;
