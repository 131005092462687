import React from "react";
import styles from "./style.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.copyrightNote}>copyright © 2023 sitedb.io</div>
    </div>
  );
};

export default Footer;
