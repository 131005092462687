import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  )
}

export interface SerializedError {
  name?: string
  message?: string
  stack?: string
  code?: string
}

export function getSafeServiceErrorMessage(error: FetchBaseQueryError | SerializedError | undefined): string {
  const fallbackMessage = 'An unknown error occurred';

  if (error) {
    if ('status' in error) {
      if (error.data && typeof(error.data) === 'object') {
        return 'message' in error.data && typeof(error.data.message) === 'string' ? error.data.message : fallbackMessage;
      } else if ('error' in error) {
        return typeof(error.error) === 'string' ? error.error : fallbackMessage;
      } else {
        return fallbackMessage;
      }
    } else {
      return error.message || fallbackMessage;
    }
  } else {
    return '';
  }
}
