import React from "react";
import logo from "assets/svg/logo.svg";
import styles from "./style.module.scss";

const Header = () => {
  return (
    <div className={styles.headerContainer}>
      <img src={logo} alt="logo" className={styles.logo} />
    </div>
  );
};

export default Header;
